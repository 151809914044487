<template>
  <div class="col mb-4 carte-gite text-white align-self-center">
    <div class="card h-100">
        <img :src="imageUrl" class="card-img img-fluid" :alt="ville">
        <div class="card-img-overlay">
          <p class="card-text bg-dark">{{ ville }}<br><small>{{ message }}</small></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ville',
  props: ['ville', 'message', 'imageUrl']
}
</script>

<style scoped>
.card{
  text-shadow: 2px 2px 25px #000;
}
</style>
