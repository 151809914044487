<template>
    <div class="col mb-4 carte-gite">
        <div class="card h-100 shadow-sm">
          <router-link :to="{ name: 'Gite', params: { id: gite.id} }">
            <img :src="imageUrls[0]" class="card-img-top">
          </router-link>
          <div class="card-body">
              <h3 class="card-title">{{ gite.nom }}</h3>
              <p class="card-text">
                {{ gite.capaciteAdulte }} adultes,
                {{ gite.capaciteEnfant }} enfant(s).
                <br>
                <span v-if="gite.prixWeekEnd > 0 && gite.prixSemaine > 0 && gite.prixMois > 0">
                  A.p.d <strong>{{ gite.prixWeekEnd }}</strong> € <small> / week-end</small>.
                </span>
                <span v-else >
                  Prix sur devis.
                </span>
              </p>
              <hr>
              <p style="text-align: center">
                  <router-link :to="{ name: 'Reservation', params: { id: gite.id} }" class="btn btn-outline-success mt-3 mx-2">Réserver</router-link>
                  <router-link :to="{ name: 'Gite', params: { id: gite.id} }" class="btn btn-info mt-3 mx-2">Découvrir</router-link>
              </p>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CarteGite',
  props: ['gite'],
  computed: {
    imageUrls () {
      const urls = []
      for (let i = 0; i < this.gite.imgs.length; i++) {
        urls.push('imgs/' + this.gite.imgs[i])
      }
      return urls
    }
  }
}
</script>

<style scoped>
</style>
