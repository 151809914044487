import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: () => import(/* webpackChunkName: "conditions" */ '../views/Conditions.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/gites',
    name: 'Gites',
    component: () => import(/* webpackChunkName: "gites" */ '../views/Gites.vue')
  },
  {
    path: '/gites/:id',
    name: 'Gite',
    component: () => import(/* webpackChunkName: "gite" */ '../views/Gite.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/Legal.vue')
  },
  {
    path: '/reservation/:id',
    name: 'Reservation',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/Reservation.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
