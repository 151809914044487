<template>
  <div class="sticky-top p-3 center container-fluid" style="background-color: rgba(250,250,250,0.95)">
    <div class="row self-cente">
      <div class="col-3">
        <router-link to="/" v-if="$route.name != 'Home' && $route.name != 'Gite' && $route.name != 'Reservation' " class="btn btn-sm btn-outline-dark">Accueil</router-link>
        <router-link to="/gites" v-if="$route.name === 'Gite' || $route.name === 'Reservation'" class="btn btn-sm btn-outline-dark">Tous les gîtes</router-link>
      </div>
      <div class="col-6"><h1 class="h4 accent">Le Relais de Léonie</h1></div>
    </div>
  </div>
  <div v-if="displayCookiePrompt" class="center pt-2 container-fluid bg-primary">
    <div class="row shadow-sm">
      <p class="text-white" style="text-align: center; width: 100%;">
        Ce site utilise des cookies pour améliorer votre navigation ainsi qu'à des fins statistiques.
        <span class="btn btn-sm btn-success" @click="displayCookiePrompt = false">Ok</span>
        <router-link to="/legal" class="btn btn-sm btn-outline-black">En savoir plus</router-link>
      </p>
    </div>
  </div>
  <nav id="nav" class="navbar fixed-bottom navbar-expand-sm navbar-light bg-light">
    <a class="navbar-brand" href="/"><img src="/logo/relais-leonie-tournai.png" class="img-fluid" style="max-height: 60px;" /></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
      Menu
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <hr class="d-md-none m-3">
      <ul class="navbar-nav mr-auto" style="margin: auto;">
        <li class="nav-item">
          <router-link to="/" class="nav-link d-none d-sm-block">Le Relais</router-link>
          <router-link to="/" class="nav-link d-block d-sm-none" data-toggle="collapse" data-target=".navbar-collapse">Le Relais</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/gites" class="nav-link d-none d-sm-block">Nos gîtes</router-link>
          <router-link to="/gites" class="nav-link d-block d-sm-none" data-toggle="collapse" data-target=".navbar-collapse">Nos gîtes</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/conditions" class="nav-link d-none d-sm-block">Conditions</router-link>
          <router-link to="/conditions" class="nav-link d-block d-sm-none" data-toggle="collapse" data-target=".navbar-collapse">Conditions</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/contact" class="nav-link d-none d-sm-block">Contact & Localisation</router-link>
          <router-link to="/contact" class="nav-link d-block d-sm-none" data-toggle="collapse" data-target=".navbar-collapse">Contact & Localisation</router-link>
        </li>
      </ul>
    </div>
  </nav>

  <router-view />
  <footer class="container-fluid bg-dark text-white sticky-bottom" style="text-align: center;">
    <div class="row py-3" style="margin-bottom: 90px;">
      <div class="col-sm-4 py-3"><router-link to ="/legal">Mentions légales</router-link></div>
      <div class="col-sm-4 py-3">
          <p class="lead center">
            <small>Handcrafted by</small><br>
            <a href="https://bertrandbourgy.com">
              <img src="/logo/bertrand.png" class="img-fluid" style="max-width: 100px;" alt="bertrand"/>
            </a>
          </p>
        </div>
      <div class="col-sm-4 py-3"></div>
    </div>
  </footer>

  <div class="modal" tabindex="-1" role="dialog">
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      displayCookiePrompt: true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #e6007e;
  font-variant: bold;
}

.navbar{
  box-shadow: 0px 8px 30px black;
}

.center{
  text-align: center;
}

.accent{
  color: #e6007e;
}

</style>
