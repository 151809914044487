import { createStore } from 'vuex'

export default createStore({
  state: {
    gites: [
      {
        id: 0,
        nom: 'Bouton d’or',
        capaciteAdulte: 4,
        capaciteEnfant: 1,
        escaliers: true,
        litEnfant: true,
        salleDeBain: true,
        info: 'Style loft, cuisine équipée, 2 chambres de 1 à 2 personnes.',
        prixWeekEnd: 280,
        prixSemaine: 350,
        prixMois: 850,
        imgs: [
          'gite-0-1.jpg',
          'gite-0-2.jpg',
          'gite-0-3.jpg',
          'gite-0-4.jpg',
          'gite-0-5.jpg',
          'gite-0-6.jpg',
          'gite-0-7.jpg',
          'gite-0-8.jpg'
        ]
      },
      {
        id: 1,
        nom: 'Bleuet',
        capaciteAdulte: 2,
        capaciteEnfant: 1,
        escaliers: false,
        litEnfant: true,
        salleDeBain: true,
        info: 'Coin cuisine, séjour, 1 chambre.',
        prixWeekEnd: 210,
        prixSemaine: 300,
        prixMois: 650,
        imgs: [
          'gite-1-1.jpg',
          'gite-1-2.jpg',
          'gite-1-3.jpg',
          'gite-1-4.jpg',
          'gite-1-5.jpg',
          'gite-1-6.jpg',
          'gite-1-7.jpg'
        ]
      },
      {
        id: 2,
        nom: 'Coquelicot',
        capaciteAdulte: 4,
        capaciteEnfant: 2,
        escaliers: false,
        litEnfant: true,
        salleDeBain: true,
        info: 'Cuisine équipée, séjour, 2 chambres.',
        prixWeekEnd: 240,
        prixSemaine: 330,
        prixMois: 750,
        imgs: [
          'gite-2-1.jpg',
          'gite-2-2.jpg',
          'gite-2-3.jpg',
          'gite-2-4.jpg',
          'gite-2-5.jpg',
          'gite-2-6.jpg'
        ]
      },
      {
        id: 3,
        nom: 'Coquelicot + Bleuet',
        capaciteAdulte: 6,
        capaciteEnfant: 3,
        escaliers: false,
        litEnfant: true,
        salleDeBain: true,
        info: 'Cuisine équipée, séjour, 3 chambres. Comprend les gîtes coquelicot et bleuet décloisonnés.',
        prixWeekEnd: 280,
        prixSemaine: 350,
        prixMois: 850,
        imgs: [
          'gite-3-1.jpg',
          'gite-1-1.jpg',
          'gite-1-2.jpg',
          'gite-1-3.jpg',
          'gite-1-4.jpg',
          'gite-1-5.jpg',
          'gite-1-6.jpg',
          'gite-1-7.jpg',
          'gite-2-1.jpg',
          'gite-2-2.jpg',
          'gite-2-3.jpg',
          'gite-2-4.jpg',
          'gite-2-5.jpg',
          'gite-2-6.jpg'
        ]
      },
      {
        id: 4,
        nom: 'Pâquerette',
        capaciteAdulte: 4,
        capaciteEnfant: 1,
        escaliers: true,
        litEnfant: true,
        salleDeBain: true,
        info: 'Cuisine équipée, séjour, 2 chambres',
        prixWeekEnd: 240,
        prixSemaine: 330,
        prixMois: 750,
        imgs: [
          'gite-4-1.jpg',
          'gite-4-2.jpg',
          'gite-4-3.jpg',
          'gite-4-4.jpg',
          'gite-4-5.jpg',
          'gite-4-6.jpg',
          'gite-4-7.jpg'
        ]
      },
      {
        id: 5,
        nom: 'Reine des Près',
        capaciteAdulte: 2,
        capaciteEnfant: 1,
        escaliers: true,
        litEnfant: true,
        salleDeBain: true,
        info: 'Cuisine équipée, séjour, 2 chambres.',
        prixWeekEnd: 220,
        prixSemaine: 310,
        prixMois: 700,
        imgs: [
          'gite-5-1.jpg',
          'gite-5-2.jpg',
          'gite-5-3.jpg',
          'gite-5-4.jpg',
          'gite-5-5.jpg',
          'gite-5-6.jpg',
          'gite-5-7.jpg',
          'gite-5-8.jpg',
          'gite-5-9.jpg',
          'gite-5-10.jpg'
        ]
      },
      {
        id: 6,
        nom: 'Marguerite',
        capaciteAdulte: 8,
        capaciteEnfant: 4,
        escaliers: true,
        litEnfant: true,
        salleDeBain: true,
        info: 'Cuisine équipée, grand séjour, 4 chambres doubles, deux salles de bain.',
        prixWeekEnd: 0,
        prixSemaine: 0,
        prixMois: 0,
        imgs: [
          'gite-6-1.jpg',
          'gite-6-2.jpg',
          'gite-6-3.jpg',
          'gite-6-4.jpg',
          'gite-6-5.jpg',
          'gite-6-6.jpg',
          'gite-6-7.jpg',
          'gite-6-8.jpg'
        ]
      }
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
