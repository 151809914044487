<template>
  <div class="home">
    <div class="container-fluid cover" :style="{ backgroundImage: 'url(' + require('@/assets/tournai.jpg') + ')' }">
      <div class="row">
        <!-- header -->
        <div class="col-sm-8 p-3 text-white">
          <h1 class="h1 pt-3">Bienvenue chez vous !</h1>
          <p>
            <img src="/logo/relais-leonie-tournai.png" class="img-fluid" style="max-width: 200px;"/>
          </p>
          <p class="lead pb-3">
              Nos gîtes, situés près de Tournai, sont équipés pour le quotidien et prêts à vous accueillir.
            </p>
        </div>
        <div class="col-sm-4 align-self-center">
          <p>
           <router-link to="/gites" class="btn btn-xl btn-info">Découvrez les gîtes !</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="container shadow bg-white p-3 rounded" style="margin-top: -25px;">
      <!-- accroche -->
      <div class="row py-3">
        <div class="col-sm-5 align-self-center">
          <img src="@/assets/gites/gite-5-1.jpg" class="img-fluid"/>
        </div>
        <div class="col-sm-7">
          <h2 class="h4 py-3">Nous avons rénové une fermette pleine de charme...</h2>
          <p>
            Afin de vous proposer de nombreux gîtes tout équipés dans le paisible village d'Orcq,
            à trois kilomètres de Tournai.
          </p>
          <p class="pt-3">
            Pour les vacances, le travail ou tout simplement pour vous dépanner,
            nos gîtes sauront vous apporter tout le confort dont vous avez besoin.
          </p>
        </div>
      </div>
    </div>
    <div class="container py-3 my-3">
    <!-- Nos gîtes -->
      <div class="row py-3">
        <div class="col-8 align-self-center">
          <h2 class="h4">Découvrez nos gîtes.</h2>
        </div>
        <div class="col-4 align-self-center" style="text-align: right;">
          <router-link to="/gites" class="btn btn-outline-success">Tout découvrir</router-link>
        </div>
      </div>
      <p class="lead">
        Tous nos gîtes sont équipés du wi-fi pour Internet, d'un espace cuisine et d'un parking intérieur privé.
        <br><small>Les animaux ne sont pas admis.</small>
      </p>
      <div class="row row-cols-1 row-cols-md-3 d-flex flex-row flex-nowrap overflow-auto card-block rounded">
        <CarteGite v-for="gite in gites" v-bind:key="gite.id" :gite="gite"  style="margin-left: -30px; margin-right: 10px;"/>
      </div>
    </div>
    <!-- Localisation -->
    <div class="container py-3">
      <div class="row">
        <div class="col-sm-4">
          <h2 class="h4">Nous sommes près de tout !</h2>
          <p class="lead">
            509, Chaussée de Lille<br>
            7501 Orcq<br>
            Belgique.
          </p>
        </div>
        <div class="col-sm-8">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10129.092222646705!2d3.3437914!3d50.6034635!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7a3b210f821b2dd9!2sLE%20RELAIS%20DE%20LEONIE!5e0!3m2!1sfr!2sbe!4v1606385710374!5m2!1sfr!2sbe" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" class="rounded-xl"></iframe>
        </div>
      </div>
      <div class="row center">
        <Ville ville="Tournai" message="À 5 min en voiture." imageUrl="villes/tournai.jpg" class="col-12 col-sm-6 col-lg-3"></Ville>
        <Ville ville="Tournai Ouest" message="À 2 min en voiture." imageUrl="villes/tournai-ouest.jpg" class="col-12 col-sm-6 col-lg-3"></Ville>
        <Ville ville="Mont-Saint-Aubert" message="À 15 min en voiture." imageUrl="villes/montsaintaubert.jpg" class="col-12 col-sm-6 col-lg-3"></Ville>
        <Ville ville="Lille (France)" message="À 25 min en voiture." imageUrl="villes/lille.jpg" class="col-12 col-sm-6 col-lg-3"></Ville>
      </div>
    </div>
  </div>
</template>

<script>
import CarteGite from '@/components/CarteGite.vue'
import Ville from '@/components/Ville.vue'

export default {
  name: 'Home',
  components: {
    CarteGite, Ville
  },
  computed: {
    gites () {
      return this.$store.state.gites
    }
  }
}
</script>

<style scoped>
.cover{
  background-attachment: fixed;
  background-position: bottom left;
  text-align: center;
}

.card-block{
  transition: all 0.2s ease-in-out !important;
}
</style>
